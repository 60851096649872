import env from 'react-dotenv';
import { v4 as uuidv4 } from 'uuid';
import { getCookie, setCookie } from '../utilities/helpers';

const fbqStandardEvents = {
    add_payment_info: 'AddPaymentInfo',
    add_to_cart: 'AddToCart',
    begin_checkout: 'InitiateCheckout',
    login: 'Lead',
    purchase: 'Purchase'
}

export const googleConversionRates = {
    composition_select: {q: 0.75, a1: 0.000401},
    photo_upload: {q: 0.75, a1: 0.001505},
    ready_to_buy: {q: 0.333, a1: 0.40},
    add_to_cart: {q: 0.2, a1: 0.08},
    begin_checkout: {q: 0.2, a1: 0.08},
    payment: {q: 1, a1: 1},
    login: {q: 0.15, a1: 0.012},
}

export function calculateReadyToBuyValuePercent(number) {
    var valuesMap = {
        1: 0.005,
        2: 0.01,
        3: 0.015,
        4: 0.015,
    };
    
    if (valuesMap[number] !== undefined) {
        return valuesMap[number];
    } else {
        return calculateConversion('ready_to_buy', number);
    }
}

export const calculateConversion = (event_name, n) => {
    if (googleConversionRates.hasOwnProperty(event_name)) {
        const {q, a1} = googleConversionRates[event_name];
        return a1 * q ** (n - 1)
    }
    return 0;
}

const getEventAndTracking = (event_name) => {
    if (fbqStandardEvents.hasOwnProperty(event_name)) {
        return [fbqStandardEvents[event_name], 'track'];
    } else {
        return [event_name, 'trackCustom'];
    }
}

const hashUserData = async (value) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(value);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
}

const normalizeEmail = (email) => {
    let normalizedEmail = email.trim().toLowerCase();
    if (normalizedEmail.endsWith("@gmail.com") || normalizedEmail.endsWith("@googlemail.com")) {
        // Usuń wszystkie kropki przed znakiem @ dla adresów Gmail.
        const parts = normalizedEmail.split('@');
        normalizedEmail = parts[0].replace(/\./g, '') + '@' + parts[1];
    }
    return normalizedEmail;
}

const normalizePhoneNumber = (phoneNumber) => {
    const digits = phoneNumber.replace(/\D/g, '');
    return `+${digits}`;
}

const normalizeName = (name) => {
    return name.replace(/[\d\s\W_]+/g, '').toLowerCase().trim();
}

export async function pushEventToGTM(event_name, user = null, event_parameters = {}) {
    const eventID = uuidv4();

    const fbpCookie = getCookie('_fbp');

    const fbcCookie = getCookie('_fbc');


    if (env.GTM_ENABLED) {
        const user_data = {};

        if (fbpCookie) {
            user_data.fbp = fbpCookie;
        }
        if (fbcCookie) {
            user_data.fbc = fbcCookie;
        }
    
        // Jeśli user jest dostępny, dodajemy jego dane
        if (user) {
            if (user.email) {
                user_data.sha256_email_address = await hashUserData(normalizeEmail(user.email));
                user_data.email_address = await hashUserData(normalizeEmail(user.email));
                user_data.email = await hashUserData(normalizeEmail(user.email));
            }
            if (user.phone_number) {
                user_data.sha256_phone_number = await hashUserData(normalizePhoneNumber(user.phone_number));
                user_data.phone_number = await hashUserData(normalizePhoneNumber(user.phone_number));
            }
            user_data.address = {}

            if (user.first_name) {
                user_data.address.sha256_first_name = await hashUserData(normalizeName(user.first_name));
                user_data.address.first_name = await hashUserData(normalizeName(user.first_name));
            }
            if (user.last_name) {
                user_data.address.sha256_last_name = await hashUserData(normalizeName(user.last_name));
                user_data.address.last_name = await hashUserData(normalizeName(user.last_name));
            }
            if (user.city) {
                user_data.address.city = user.city;
            }
            if (user.postal_code) {
                user_data.address.postal_code = user.postal_code;
            }
            if (user.country) {
                user_data.address.country = user.country;
            }
        }

        // Przygotowujemy event_params
        const event_params = {
            '$timestamp': new Date().toISOString(),
            ...event_parameters,
            user_data, // Zawsze dodajemy user_data
        };

        console.log('pushEventToGTM', event_name, { ...event_params, 'event_id': eventID })

        window.gtag('event', event_name, { ...event_params, 'event_id': eventID });
    }

    if (
        env.PIXEL_ID &&
        getCookie('ad_personalization') === 'granted' &&
        window.fbq
    ) {

        const user_data = {};

        if (fbpCookie) {
            user_data.fbp = fbpCookie;
        }
        if (fbcCookie) {
            user_data.fbc = fbcCookie;
        }
    
        if (user) {
            if (user.email) {
                user_data.em = await hashUserData(normalizeEmail(user.email));
            }
            if (user.phone_number) {
                user_data.ph = await hashUserData(normalizePhoneNumber(user.phone_number));
            }
            if (user.first_name) {
                user_data.fn = await hashUserData(normalizeName(user.first_name));
            }
            if (user.last_name) {
                user_data.ln = await hashUserData(normalizeName(user.last_name));
            }
            if (user.city) {
                user_data.ct = user.city.toLowerCase();
            }
            if (user.postal_code) {
                user_data.zp = user.postal_code;
            }
            if (user.country) {
                user_data.country = user.country.toLowerCase();
            }
            if (user.external_id) {
                user_data.external_id = user.external_id;
            }
        }

        // Przygotowujemy event_params
        const event_params = {
            '$timestamp': new Date().toISOString(),
            ...event_parameters,
            ...user_data,
        };
        
        const [eventName, tracking] = getEventAndTracking(event_name);

        // Jeśli mamy dodatkowe dane użytkownika, ponownie inicjalizujemy fbq z tymi danymi
        if (Object.keys(user_data).length > 0) {
            window.fbq('init', env.PIXEL_ID, user_data);
        }

        window.fbq(tracking, eventName, event_params, { 'eventID': eventID });
    }
}

export function setUserIdInGTM(userId) {
    if (env.GTM_ENABLED) {
        window.gtag('config', env.GTAG_ID, {
            'user_id': userId
        });
    }
}

function pushConsentToGTM(consent_action, consent_parameter = {}) {
    if (env.GTM_ENABLED) {
        console.log('consent', consent_action, consent_parameter)
        window.gtag('consent', consent_action, {
            ...consent_parameter
        });
    }
}

export function rejectCookies() {
    pushConsentToGTM('default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied'
    })
}

export function defaultConsent() {
    const adStorage = getCookie('ad_storage') || 'denied';
    const adUserData = getCookie('ad_user_data') || 'denied';
    const adPersonalization = getCookie('ad_personalization') || 'denied';
    const analyticsStorage = getCookie('analytics_storage') || 'denied';

    pushConsentToGTM('default', {
        'ad_storage': adStorage,
        'ad_user_data': adUserData,
        'ad_personalization': adPersonalization,
        'analytics_storage': analyticsStorage
    })
}

export function acceptCookies(cookieData=null) {
    const adStorage = cookieData && !cookieData.marketing ? 'denied' : 'granted';
    const adUserData = cookieData && !cookieData.marketing ? 'denied' : 'granted';
    const adPersonalization = cookieData && !cookieData.marketing ? 'denied' : 'granted';
    const analyticsStorage = cookieData && !cookieData.analytics ? 'denied' : 'granted';
    setCookie('ad_storage', adStorage, 365)
    setCookie('ad_user_data', adUserData, 365)
    setCookie('ad_personalization', adPersonalization, 365)
    setCookie('analytics_storage', analyticsStorage, 365)

    pushConsentToGTM('update', {
        'ad_storage': adStorage,
        'ad_user_data': adUserData,
        'ad_personalization': adPersonalization,
        'analytics_storage': analyticsStorage
    })
}

